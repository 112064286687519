<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="channelProductId"
      >
        <a-input
          v-decorator="['channelProductId',{ initialValue:'0' }]"
          name="channelProductId"></a-input>
      </a-form-item>

      <a-form-item
        label="产品名称"
      >
        <a-input
          v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入产品名称' }]}
          ]"
          name="name"
          placeholder="请输入产品名称"/>
      </a-form-item>
      <a-form-item
        label="产品价格"
      >
        <a-input
          v-decorator="[
            'price',
            {rules: [{ required: true, message: '请输入产品价格' }]}
          ]"
          name="price"
          placeholder="请输入产品价格"/>
      </a-form-item>
      <a-form-item
        label="内部一级产品"
        prop="productId"
      >
        <a-select
          allowClear
          v-decorator="[
            'productId',
            {rules: [{ required: true, message: '请选择内部一级产品' }]}
          ]"
          placeholder="内部一级产品"
          name="productId"
          @change="onChange"
        >
          <a-select-option v-for="item in productdata" :key="item.productId" :value="item.productId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="内部二级产品"
        prop="productChildId"
      >
        <a-select
          allowClear
          v-decorator="[
            'productChildId',
            {rules: [{ required: true, message: '请选择内部二级产品' }]}
          ]"
          placeholder="内部二级产品"
          name="productChildId"
        >
          <a-select-option v-for="item in productChilddata" :key="item.productChildId" :value="item.productChildId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="排序"
      >
        <a-input
          v-decorator="[
            'listorder',
            {rules: [{ required: true, message: '请输入排序' }]}
          ]"
          name="path"
          placeholder="请输入排序"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  import { allList } from '@/api/product'
  import { allList as allListProductchild } from '@/api/productchild'
  export default {
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this),
        productdata: [],
        productChilddata: []
      }
    },
    created () {
      this.getAllList({})
    },
    methods: {
      getAllList (params) {
        allList(params).then(res => {
          this.productdata = res.result
          this.$forceUpdate()
        })
      },
      getAllListProductchild (params) {
        allListProductchild(params).then(res => {
          this.productChilddata = res.result
          this.$forceUpdate()
        })
      },
      onChange (value, option) {
        this.form.setFieldsValue({
          productChildId: undefined
        })
         this.getAllListProductchild({ productId: value })
      }

    }
  }
</script>
