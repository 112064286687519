var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"channelProductId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['channelProductId',{ initialValue:'0' }]),expression:"['channelProductId',{ initialValue:'0' }]"}],attrs:{"name":"channelProductId"}})],1),_c('a-form-item',{attrs:{"label":"产品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {rules: [{ required: true, message: '请输入产品名称' }]}
        ]),expression:"[\n          'name',\n          {rules: [{ required: true, message: '请输入产品名称' }]}\n        ]"}],attrs:{"name":"name","placeholder":"请输入产品名称"}})],1),_c('a-form-item',{attrs:{"label":"产品价格"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'price',
          {rules: [{ required: true, message: '请输入产品价格' }]}
        ]),expression:"[\n          'price',\n          {rules: [{ required: true, message: '请输入产品价格' }]}\n        ]"}],attrs:{"name":"price","placeholder":"请输入产品价格"}})],1),_c('a-form-item',{attrs:{"label":"内部一级产品","prop":"productId"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'productId',
          {rules: [{ required: true, message: '请选择内部一级产品' }]}
        ]),expression:"[\n          'productId',\n          {rules: [{ required: true, message: '请选择内部一级产品' }]}\n        ]"}],attrs:{"allowClear":"","placeholder":"内部一级产品","name":"productId"},on:{"change":_vm.onChange}},_vm._l((_vm.productdata),function(item){return _c('a-select-option',{key:item.productId,attrs:{"value":item.productId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"内部二级产品","prop":"productChildId"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'productChildId',
          {rules: [{ required: true, message: '请选择内部二级产品' }]}
        ]),expression:"[\n          'productChildId',\n          {rules: [{ required: true, message: '请选择内部二级产品' }]}\n        ]"}],attrs:{"allowClear":"","placeholder":"内部二级产品","name":"productChildId"}},_vm._l((_vm.productChilddata),function(item){return _c('a-select-option',{key:item.productChildId,attrs:{"value":item.productChildId}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'listorder',
          {rules: [{ required: true, message: '请输入排序' }]}
        ]),expression:"[\n          'listorder',\n          {rules: [{ required: true, message: '请输入排序' }]}\n        ]"}],attrs:{"name":"path","placeholder":"请输入排序"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }